import React from 'react';

import Layout from './components/Layout';
import Footer from './components/Footer';
import Header from './components/Header';
import PageFooter from './components/PageFooter';
import config from '../config';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from "@material-ui/core/Chip";
import {Offline} from "react-detect-offline";
import {Link} from 'gatsby';

const IndexPage = (props) => {
    const { path } = props;
    const title = path.replace(/_/g, ' ');
    const publicUrl = props.pageContext.node.publicURL;
    return (
        <Layout>
            <section id="main">
                <Header title={title}/>
                <Offline>
                    <Chip
                        icon={<WarningIcon />}
                        label="Uwaga, jesteś offline!"
                        color="secondary"
                    />
                </Offline>
                <p>
                    <a href={publicUrl} download><i className="fas fa-file-download"/> {config.download}</a>
                </p>
                <audio className="react-audio-player " controls preload="metadata"
                       src={publicUrl}
                       title={title}
                       controlsList={"nodownload"} />
                <Footer />
                <div className={"back"}><Link to={"/"}><i className="fas fa-arrow-circle-left"/> powrót do listy</Link></div>
            </section>
            <PageFooter />
        </Layout>
    );
};

export default IndexPage;
